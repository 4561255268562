<template>
  <div>
    <pre>Tracking</pre>
    <label for="action">Action: </label>
    <input id="action" type="text" v-model="eventAction" />
    <br />
    <button @click="onClick">PUSH</button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

import { push } from '@/inc/tracking'

export default defineComponent({
  name: 'Tracking',
  setup() {
    const eventAction = ref('test')
    let count = 0

    const onClick = () => {
      count += 1
      push({
        name: eventAction.value,
        category: 'dev',
        action: `test-${eventAction.value}`,
        label: `click-${count}`,
      })
    }

    return {
      eventAction,
      onClick,
    }
  },
})
</script>
