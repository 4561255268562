
import { defineComponent, ref } from 'vue'

import { push } from '@/inc/tracking'

export default defineComponent({
  name: 'Tracking',
  setup() {
    const eventAction = ref('test')
    let count = 0

    const onClick = () => {
      count += 1
      push({
        name: eventAction.value,
        category: 'dev',
        action: `test-${eventAction.value}`,
        label: `click-${count}`,
      })
    }

    return {
      eventAction,
      onClick,
    }
  },
})
